import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import InstaPayLoader from '../Loader'
import { UserContext } from '../../Context/UserContext'
import { decryptData } from '../../Helper/EssentilaMethods/Decryption'
import axios from 'axios'

const PaymentStatus = () => {
    const { getUserBeneficiaries, beneficiaries, authHeader } = useContext(UserContext)
    const { type, status, code } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        const searchUser = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_IP_DEV_API}/api/account/search-users/${code}`,
                    {
                        headers: authHeader,
                    }
                );
                const data = await decryptData(response.data.data);
                return `${data?.users?.[0]?.first_name} ${data?.users?.[0]?.last_name}`
            } catch (error) {
                return ""
            }
        };

        const fetchDetailsAndRedirect = () => {
            if (type === "international") {
                getUserBeneficiaries()
                    .then(() => {
                        const benef = beneficiaries.find(item => item._id === code)
                        navigate(`/payments?payment_action=send_money&send_type=${type}&transaction_status=${status}&transaction_id=test&code=${code}&title=${benef?.first_name}_${benef?.last_name}`)
                    })
            } else {
                searchUser()
                    .then(res => {
                        navigate(`/payments?payment_action=send_money&send_type=${type}&transaction_status=${status}&transaction_id=test&code=${code}&title=${res}`)
                    })
            }
        }

        if (status === "success") {
            fetchDetailsAndRedirect()
        } else {
            navigate(`/payments?payment_action=send_money&send_type=${type}&transaction_status=${status}&transaction_id=test&code=${code}&title=nope`)
        }
    }, [type, status])
    return (
        <div style={{
            height: "100vh",
            width: "100vw"
        }}>
            <InstaPayLoader />
        </div>
    )
}

export default PaymentStatus