import React, { useEffect } from 'react'
import Routers from "./RouteNew"
import CustomizerProvider from './Helper/Customizer/CustomizerProvider'
import ProjectProvider from './Helper/Project/ProjectProvider'
import FilterProvider from "./Helper/filter/FilterProvider"
import CartProvider from './Helper/cart/CartProvider';
import ProductProvider from './Helper/product/ProductProvider';
import WishListProvider from "./Helper/wishlist/WishProvider"
import EmailProvider from "./Helper/email/EmailProvider"
import ChatProvider from "./Helper/chat-app/ChatProvider"
import BookmarkProvider from "./Helper/bookmark/BookmarkProvider"
import ContactProvider from './Helper/Contact/ContactProvider'
import TaskProvider from './Helper/task-app/TaskProvider'
import TodoProvider from "./Helper/Todo/TodoProvider"
import TableProvider from "./Helper/Table/TableProvider"
import Leatning from "./Helper/Leatning/LearningProvider"
import FAQProvider from './Helper/Faq/FaqProvider'
import JobSearchProvider from './Helper/JobSearch/JobSearchProvider'
import GalleryProvider from "./Helper/Gallery/GalleryProvider"
import GoogleChartProvider from "./Helper/GoogleChart/GoogleChartProvider"
import ChartistProvider from "./Helper/Chartist/ChartistProvider"
import AnimationThemeProvider from './Helper/AnimationTheme/AnimationThemeProvider'
import UserState from './Context/UserContext'
import { useTranslation } from 'react-i18next'
import InactivityWrapper from './InactivityWrapper'
import { BrowserRouter } from 'react-router-dom'
import { Suspense } from 'react'
import InstaPayLoader from './Components/Loader'
import { TimezonesProvider } from './Context/TimezoneContext'
import ErrorBoundary from './ErrorBoundary'

const App = () => {
  // const { i18n } = useTranslation()

  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const langParam = urlParams.get('lang');

  //   if (langParam) {
  //     i18n.changeLanguage(langParam)
  //   }
  // }, []);

  useEffect(() => {
    const clearLocalStorage = () => {
      const con = window.confirm("Your account will be logged out after closing this window, Are you sure you want to continue?")
      if (con) {
        const coun = localStorage.getItem("ip_country")
        localStorage.clear()
        localStorage.setItem("ip_country", coun)
      }
    }
    window.addEventListener("beforeunload", clearLocalStorage)

    return () => {
      window.removeEventListener("beforeunload", clearLocalStorage)
    }
  }, [])
  return (
    <div className='App'>
      <ErrorBoundary>
        <Suspense fallback={<InstaPayLoader opacity={1} />}>
          <TimezonesProvider>
            <BrowserRouter>
              <UserState>
                <InactivityWrapper>
                  <CustomizerProvider>
                    <WishListProvider>
                      <FilterProvider>
                        <CartProvider>
                          <ProjectProvider>
                            <ProductProvider>
                              <ContactProvider>
                                <EmailProvider>
                                  <ChatProvider>
                                    <TaskProvider>
                                      <TodoProvider>
                                        <TableProvider>
                                          <Leatning>
                                            <FAQProvider>
                                              <JobSearchProvider>
                                                <GalleryProvider>
                                                  <GoogleChartProvider>
                                                    <ChartistProvider>
                                                      <BookmarkProvider>
                                                        <AnimationThemeProvider>
                                                          {" "}
                                                          <Routers />
                                                        </AnimationThemeProvider>{" "}
                                                      </BookmarkProvider>
                                                    </ChartistProvider>
                                                  </GoogleChartProvider>
                                                </GalleryProvider>
                                              </JobSearchProvider>
                                            </FAQProvider>
                                          </Leatning>
                                        </TableProvider>
                                      </TodoProvider>
                                    </TaskProvider>
                                  </ChatProvider>
                                </EmailProvider>
                              </ContactProvider>
                            </ProductProvider>
                          </ProjectProvider>
                        </CartProvider>
                      </FilterProvider>
                    </WishListProvider>
                  </CustomizerProvider>
                </InactivityWrapper>
              </UserState>
            </BrowserRouter>
          </TimezonesProvider>
        </Suspense>
      </ErrorBoundary>
    </div>
  )
}

export default App;